.card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding-top: 30px; */
    align-items: center;
    border-radius: 18px;
    background-color: #ffe6d2;
    height: 350px;
    width: 270px;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.543);
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 100ms;
}

.image {
    width: 270px;
    height: 270px;
    aspect-ratio: 2/3;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.info h3 {
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
    margin: 0;
    width: 230px;
}

.title {
    width: 250px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 1000px) {
    .card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        /* padding-top: 30px; */
        align-items: center;
        border-radius: 18px;
        background-color: #ffe6d2;
        height: 350px;
        width: 270px;
        box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.543);
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 100ms;
    }

    .card:hover {
        transform: scale(1.08);
        border: solid 2px #8e1300;
        transition: 100ms;
        z-index: 1;
        position: relative;
    }

    .image {
        width: 270px;
        height: 270px;
        aspect-ratio: 2/3;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    .info h3 {
        margin-top: 10px;
        margin-bottom: 0;
        margin: 0;
        padding: 0;
        width: 230px;
    }

    .title {
        width: 250px;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
