.cardcontainer {
    width: 80vw;
    display: flex;
    /* flex-direction: row; */
    border-radius: 15px;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
.link {
    text-decoration: none;
    color: #723d46;
}

.container {
    height: 355px;
    width: 275px;
}
