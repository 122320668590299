@media screen and (min-width: 280px) {
    .detail {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }

    .container {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        padding-bottom: 50px;
    }

    .bigCard {
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        background-color: #fff3e2b3;
        flex-direction: column;
        border-radius: 35px;
        margin-top: 80px;
        margin-bottom: 40px;
        width: 90%;
        box-shadow: 0px 1px 5px 1px #0303036f;
    }

    .bigCard h1 {
        font-size: 30px;
        color: #8e1300;
        width: 90%;
    }

    .dietsAndHscore {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
    }

    .imgContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .enum {
        font-weight: 700;
    }

    .steps {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
        color: #8e1300;
        list-style: none;
        padding: 0;
    }

    .steps p {
        font-size: small;
        /* font-weight: 400; */
        padding: 0;
        margin-top: 10px;
    }
    .steps li {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-direction: row;
        gap: 10px;
        padding: 0;
        margin: 0;
    }

    .stepCont {
        border-top: solid 1px #8e1300;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        /* gap: 10px; */
        padding: 0;
        margin: 0;
        width: 90%;
    }
    .stepCont h2 {
        margin: 0;
        padding: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        color: #8e1300;
        font-size: medium;
    }

    .summaryCont {
        border-top: solid 1px #8e1300;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        /* gap: 5px; */
        width: 90%;
    }

    .summaryCont h2 {
        margin: 0;
        padding: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        color: #8e1300;
        font-size: medium;
    }

    .summary {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-self: flex-start;
        flex-direction: column;
        text-align: left;
        color: #8e1300;
        font-size: small;
    }

    .diets {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        font-size: 15px;
        color: #8e1300;
        width: 50%;
    }

    .card {
        display: flex;
        justify-content: center;
        gap: 25px;
        align-items: center;
        flex-direction: column;
        border-radius: 35px;
        /* margin-top: 150px; */
        width: 100%;
    }

    .healthy {
        background-color: #5da271;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 35px;
        height: 35px;
        border-radius: 100px;
        padding: 15px;
        gap: 5px;
        font-size: 10px;
        box-shadow: 0px 1px 5px 1px #0303036f;
    }
    .unhealthy {
        background-color: #e26d5c;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 35px;
        height: 35px;
        border-radius: 100px;
        padding: 15px;
        gap: 5px;
        font-size: x-large;
    }

    .regular {
        background-color: #e2b85c;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 35px;
        height: 35px;
        border-radius: 100px;
        padding: 15px;
        gap: 5px;
        font-size: x-large;
    }

    .score1 {
        color: #fff3e2;
        padding: 0;
        margin: 0;
        display: none;
    }
    .score2 {
        color: #fff3e2;
        padding: 0;
        margin: 0;
        font-size: 1.3rem;
    }

    .image {
        /* border: solid 1px  #8e130095; */
        border-radius: 25px;
        width: 80%;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.459);
        position: relative;
        display: inline-block;
    }
}

@media screen and (min-width: 800px) {
    .detail {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 50px;
        width: 100%;
    }

    .bigCard {
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        background-color: #fff3e2b3;
        flex-direction: column;
        border-radius: 35px;
        margin-top: 80px;
        padding-bottom: 30px;
        width: 90%;
    }

    .bigCard h1 {
        font-size: 35px;
        color: #8e1300;
    }

    .imgContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .enum {
        font-weight: 700;
    }

    .steps {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
        width: 100%;
        color: #8e1300;
        list-style: none;
    }

    .steps p {
        font-size: larger;
        /* font-weight: 400; */
        padding: 0;
        margin-top: 10px;
    }
    .steps li {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-direction: row;
        gap: 10px;
        padding: 0;
        margin: 0;
    }

    .stepCont {
        border-top: solid 1px #8e1300;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        /* gap: 10px; */
        width: 80%;
        padding: 0;
        margin: 0;
    }
    .stepCont h2 {
        color: #8e1300;
        padding: 0;
        font-size: large;
        padding-top: 10px;
    }

    .summaryCont {
        border-top: solid 1px #8e1300;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        width: 80%;
    }

    .summaryCont h2 {
        color: #8e1300;
        padding: 0;
        font-size: large;
        padding-top: 10px;
    }

    .summary {
        padding-top: 6px;
        display: flex;
        justify-content: center;
        align-self: flex-start;
        flex-direction: column;
        text-align: left;
        width: 100%;
        color: #8e1300;
        font-size: larger;
    }

    .diets {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        font-size: large;
        color: #8e1300;
        width: 70%;
    }

    .dietsAndHscore {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 50%;
    }

    .image {
        /* border: solid 1px  #8e130095; */
        border-radius: 25px;
        width: 40%;
        height: 100%;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.459);
    }

    .card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        border-radius: 35px;
        padding-bottom: 30px;
        width: 90%;
    }

    .healthy {
        background-color: #5da271;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        padding: 20px;
        gap: 5px;
        font-size: x-large;
    }
    .unhealthy {
        background-color: #e26d5c;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        padding: 20px;
        gap: 5px;
        font-size: x-large;
    }

    .regular {
        background-color: #e2b85c;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        padding: 20px;
        gap: 5px;
        font-size: x-large;
    }

    .score1 {
        color: #fff3e2;
        padding: 0;
        margin: 0;
        display: none;
    }
    .score2 {
        color: #fff3e2;
        padding: 0;
        margin: 0;
        font-size: 1.3rem;
    }
}

@media screen and (min-width: 1000px) {
    .detail {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 50px;
        width: 100%;
    }

    .bigCard {
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        background-color: #fff3e2b3;
        flex-direction: column;
        border-radius: 35px;
        margin-top: 80px;
        padding-bottom: 30px;
        width: 90%;
    }

    .bigCard h1 {
        font-size: 35px;
        color: #8e1300;
    }

    .imgContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .enum {
        font-weight: 700;
    }

    .steps {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
        width: 100%;
        color: #8e1300;
        list-style: none;
    }

    .steps p {
        font-size: larger;
        /* font-weight: 400; */
        padding: 0;
        margin-top: 10px;
    }
    .steps li {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-direction: row;
        gap: 10px;
        padding: 0;
        margin: 0;
    }

    .stepCont {
        border-top: solid 1px #8e1300;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        /* gap: 10px; */
        width: 80%;
        padding: 0;
        margin: 0;
    }
    .stepCont h2 {
        color: #8e1300;
        padding: 0;
        font-size: x-large;
        padding-top: 15px;
    }

    .summaryCont {
        border-top: solid 1px #8e1300;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        width: 80%;
    }

    .summaryCont h2 {
        color: #8e1300;
        padding: 0;
        font-size: x-large;
        padding-top: 15px;
    }

    .summary {
        padding-top: 6px;
        display: flex;
        justify-content: center;
        align-self: flex-start;
        flex-direction: column;
        text-align: left;
        width: 100%;
        color: #8e1300;
        font-size: larger;
        padding-bottom: 10px;
    }

    .diets {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        font-size: x-large;
        color: #8e1300;
        width: 55%;
    }

    .dietsAndHscore {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 60%;
        gap: 5%;
    }

    .image {
        /* border: solid 1px  #8e130095; */
        border-radius: 25px;
        width: 30%;
        height: 100%;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.459);
    }

    .card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        border-radius: 35px;
        padding-bottom: 30px;
        width: 90%;
    }

    .healthy {
        background-color: #5da271;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        padding: 50px;
        gap: 5px;
        font-size: x-large;
    }
    .unhealthy {
        background-color: #e26d5c;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        padding: 50px;
        gap: 5px;
        font-size: x-large;
    }

    .regular {
        background-color: #e2b85c;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        padding: 50px;
        gap: 5px;
        font-size: x-large;
    }

    .score1 {
        color: #fff3e2;
        padding: 0;
        margin: 0;
        display: block;
    }
    .score2 {
        color: #fff3e2;
        padding: 0;
        margin: 0;
        font-size: 1.7rem;
    }
}
