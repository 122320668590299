@media screen and (min-width: 280px) {
    .landing {
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        background-color: #ffe6d2b5;
        width: 90%;
        height: 400px;
        border-radius: 50px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    }

    .bienvenido {
        color: #e26d5c;
        width: 90%;
    }

    .boton {
        background-color: #e26d5c;
        cursor: pointer;
        border: solid 2px transparent;
        padding: 16px 32px;
        color: #fff;
        font-weight: bold;
        position: relative;
        border-radius: 35px;
        font-size: x-large;
    }

    .boton:hover {
        border: solid 3px#E26D5C;
        transform: scale(1.1);
        background-color: #e26e5c4e;
        color: #e26d5c;
        transition: all 10ms;
    }
}
@media screen and (min-width: 600px) {
    .landing {
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        background-color: #ffe6d2b5;
        width: 90%;
        height: 400px;
        border-radius: 50px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    }

    .bienvenido {
        color: #e26d5c;
        font-weight: bold;
        width: 90%;
    }

    .boton {
        background-color: #e26d5c;
        cursor: pointer;
        border: solid 2px transparent;
        padding: 16px 32px;
        color: #fff;
        font-weight: bold;
        position: relative;
        border-radius: 35px;
        font-size: x-large;
    }

    .boton:hover {
        border: solid 3px#E26D5C;
        transform: scale(1.1);
        background-color: #e26e5c4e;
        color: #e26d5c;
        transition: all 10ms;
    }
}
@media screen and (min-width: 800px) {
    .landing {
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        background-color: #ffe6d2b5;
        width: 60%;
        height: 400px;
        border-radius: 50px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    }

    .bienvenido {
        color: #e26d5c;
        font-weight: bold;
        width: 90%;
    }

    .boton {
        background-color: #e26d5c;
        cursor: pointer;
        border: solid 2px transparent;
        padding: 16px 32px;
        color: #fff;
        font-weight: bold;
        position: relative;
        border-radius: 35px;
        font-size: x-large;
    }

    .boton:hover {
        border: solid 3px#E26D5C;
        transform: scale(1.1);
        background-color: #e26e5c4e;
        color: #e26d5c;
        transition: all 10ms;
    }
}
@media screen and (min-width: 1000px) {
    .landing {
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        background-color: #ffe6d2b5;
        width: 50%;
        height: 400px;
        border-radius: 50px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    }

    .bienvenido {
        color: #e26d5c;
        font-weight: bold;
        font-size: 17.5px;
        width: 90%;
    }

    .boton {
        background-color: #e26d5c;
        cursor: pointer;
        border: solid 2px transparent;
        padding: 16px 32px;
        color: #fff;
        font-weight: bold;
        position: relative;
        border-radius: 35px;
        font-size: x-large;
    }

    .boton:hover {
        border: solid 3px#E26D5C;
        transform: scale(1.1);
        background-color: #e26e5c4e;
        color: #e26d5c;
        transition: all 10ms;
    }
}
