@media screen and (min-width: 280px) {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding-bottom: 50px;
    }

    .pages {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .ordersAndFilters {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-bottom: 40px;
        margin-top: 70px;
        /* padding-top: 10px; */
        border-top: none;
    }

    .marginC {
        margin-top: 40px;
    }

    .button {
        padding-left: 10px;
        background-color: #ff847153;
        border-radius: 25px;
        border: solid 2px #8e1300;
        color: #8e1300;
        cursor: pointer;
        height: 40px;
        width: 20%;
        font-size: x-small;
    }

    .botons {
        padding-left: 10px;
        background-color: #ff847153;
        border-radius: 25px;
        border: solid 2px #8e1300;
        color: #8e1300;
        cursor: pointer;
        height: 40px;
        width: 20%;
        font-size: x-small;
    }

    .botons:focus {
        outline: none !important;
        border: solid 2px #bf3c52;
        background-color: #bf3c5200;
        color: #bf3c52;
    }

    .titles {
        color: #8e1300;
    }

    .info {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .orderContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }
}

@media screen and (min-width: 800px) {
    .container {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding-bottom: 0;
    }

    .pages {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .ordersAndFilters {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 40px;
        margin-top: 70px;
        border-top: none;
    }

    .marginC {
        margin-top: 40px;
    }

    .button {
        padding-left: 10px;
        background-color: #ff847153;
        border-radius: 25px;
        border: solid 2px #8e1300;
        color: #8e1300;
        cursor: pointer;
        height: 40px;
        width: 19%;
        font-size: x-small;
    }

    .botons {
        padding-left: 10px;
        background-color: #ff847153;
        border-radius: 25px;
        border: solid 2px #8e1300;
        color: #8e1300;
        cursor: pointer;
        height: 40px;
        width: 19%;
        font-size: x-small;
    }

    .botons:focus {
        outline: none !important;
        border: solid 2px #bf3c52;
        background-color: #bf3c5200;
        color: #bf3c52;
    }

    .titles {
        color: #8e1300;
    }

    .info {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .orderContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 90%;
    }
}
@media screen and (min-width: 1000px) {
    .container {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .pages {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .ordersAndFilters {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 40px;
        margin-top: 75px;
        border-top: none;
    }

    .marginC {
        margin-top: 40px;
    }

    .button {
        padding-left: 10px;
        background-color: #ff847153;
        border-radius: 25px;
        border: solid 2px #8e1300;
        color: #8e1300;
        cursor: pointer;
        height: 40px;
        width: 19%;
        font-size: medium;
    }

    .botons {
        padding-left: 10px;
        background-color: #ff847153;
        border-radius: 25px;
        border: solid 2px #8e1300;
        color: #8e1300;
        cursor: pointer;
        height: 40px;
        width: 19%;
        font-size: medium;
    }

    .botons:focus {
        outline: none !important;
        border: solid 2px #bf3c52;
        background-color: #bf3c5200;
        color: #bf3c52;
    }

    .titles {
        color: #8e1300;
    }

    .info {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .orderContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 70%;
    }
}
