@media screen and (min-width: 280px) {
    .title {
        color: #8e1300;
        margin: 0;
        padding: 0;
        font-size: larger;
    }
    .label {
        font-size: 12px;
        color: #8e1300;
        margin: 0;
        padding: 0;
        padding-left: 13px;
    }

    .container {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 98%;
        padding-bottom: 50px;
    }

    .inForm {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .inputCont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 87px;
    }
    .inputContDiets {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .inputCont p {
        color: #ff0059;
        margin: 0;
        padding: 0;
        padding-left: 12px;
        font-size: 11px;
    }

    .input {
        height: 40px;
        border-radius: 50px;
        padding-left: 10px;
        background-color: #ff847153;
        color: #8e1300;
        border: solid 2px #8e1300;
        width: 98%;
        height: 30px;
        font-size: 11px;
    }

    .input:focus {
        transform: scale(1.02);
        outline: none !important;
    }

    .input::placeholder {
        color: #8e1300;
    }

    .form {
        background-color: #ffe6d2;
        width: 90%;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 80px;
        border: solid 2px transparent;
        box-shadow: 0px 1px 2px 1.5px #03030347;
        border-radius: 25px;
    }

    .selectedItems {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        background-color: rgba(255, 255, 255, 0);
        border-radius: 35px;
        gap: 5px;
        height: 10px;
        padding: 10px;
        /* margin-left: 5px; */
        border: solid 1px #8e1300;
        /* width: 25%; */
    }
    .selectedItems p {
        color: #8e1300;
        font-weight: 500;
        font-size: 11px;
    }

    .x {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background-color: #bc1900;
        color: #ffe6d2;
        border: solid 1px #bc1900;
        font-size: 11px;
        width: 15px;
        height: 15px;
    }
    .x:hover {
        cursor: pointer;
    }

    .sidebar_box {
        /* margin-left: 600px; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        width: 100%;
    }

    .sidebar_box h4 {
        color: #8e1300;
        font-weight: 300;
        font-size: medium;
    }

    .boton {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        height: 50px;
        width: 90%;
        border-radius: 15px;
        border: none;
        font-size: 13px;
        font-weight: 600;
        background-color: #ff847153;
        color: #bc1900;
        border: solid 2px #bc1900;
    }
}
@media screen and (min-width: 800px) {
    .title {
        color: #8e1300;
        margin: 0;
        padding: 0;
        font-size: larger;
    }
    .label {
        font-size: 15px;
        /* font-size: medium; */
        color: #8e1300;
        margin: 0;
        padding: 0;
        padding-left: 13px;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75%;
    }

    .inForm {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        gap: 7px;
        width: 100%;
    }

    .inputCont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 90px;
    }
    .inputContDiets {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .inputCont p {
        color: #ff0059;
        margin: 0;
        padding: 0;
        padding-left: 12px;
        font-size: 15px;
    }

    .input {
        /* height: 40px; */
        border-radius: 50px;
        padding-left: 10px;
        background-color: #ff847153;
        color: #8e1300;
        border: solid 2px #8e1300;
        width: 98%;
        height: 35px;
        font-size: 15px;
    }

    .input:focus {
        transform: scale(1.02);
        outline: none !important;
    }

    .input::placeholder {
        color: #8e1300;
    }

    .form {
        background-color: #ffe6d2;
        width: 90%;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 85px;

        border: solid 2px transparent;
        box-shadow: 0px 1px 2px 1.5px #03030347;
        border-radius: 25px;
    }

    .selectedItems {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        background-color: rgba(255, 255, 255, 0);
        border-radius: 35px;
        gap: 10px;
        height: 10px;
        padding: 15px;
        /* margin-left: 5px; */
        border: solid 1px #8e1300;
        /* width: 25%; */
    }
    .selectedItems p {
        color: #8e1300;
        font-weight: 500;
        font-size: medium;
    }

    .x {
        padding: 5px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background-color: #bc1900;
        color: #ffe6d2;
        border: solid 1px #bc1900;
        font-size: medium;
        width: 15px;
        height: 15px;
    }
    .x:hover {
        cursor: pointer;
    }

    .sidebar_box {
        /* margin-left: 600px; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
    }

    .sidebar_box h4 {
        color: #8e1300;
        font-weight: 300;
        font-size: medium;
    }

    .boton {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        height: 50px;
        width: 90%;
        border-radius: 15px;
        border: none;
        font-size: 13px;
        font-weight: 600;
        background-color: #ff847153;
        color: #bc1900;
        border: solid 2px #bc1900;
    }
}
@media screen and (min-width: 1400px) {
    .title {
        color: #8e1300;
        margin: 0;
        padding: 0;
        font-size: x-large;
    }
    .label {
        font-size: 17px;
        /* font-size: medium; */
        color: #8e1300;
        margin: 0;
        padding: 0;
        padding-left: 13px;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65%;
    }

    .inForm {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        gap: 7px;
        width: 100%;
    }

    .inputCont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100px;
    }
    .inputContDiets {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .inputCont p {
        color: #ff0059;
        margin: 0;
        padding: 0;
        padding-left: 12px;
        font-size: 17px;
    }

    .input {
        /* height: 40px; */
        border-radius: 50px;
        padding-left: 10px;
        background-color: #ff847153;
        color: #8e1300;
        border: solid 2px #8e1300;
        width: 98%;
        height: 40px;
        font-size: 17px;
    }

    .input:focus {
        transform: scale(1.02);
        outline: none !important;
    }

    .input::placeholder {
        color: #8e1300;
    }

    .form {
        background-color: #ffe6d2;
        width: 90%;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 90px;
        border: solid 2px transparent;
        box-shadow: 0px 1px 2px 1.5px #03030347;
        border-radius: 25px;
    }

    .selectedItems {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        background-color: rgba(255, 255, 255, 0);
        border-radius: 35px;
        gap: 10px;
        height: 10px;
        padding: 15px;
        /* margin-left: 5px; */
        border: solid 1px #8e1300;
        /* width: 25%; */
    }
    .selectedItems p {
        color: #8e1300;
        font-weight: 500;
        font-size: medium;
    }

    .x {
        padding: 5px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background-color: #bc1900;
        color: #ffe6d2;
        border: solid 1px #bc1900;
        font-size: medium;
        width: 15px;
        height: 15px;
    }
    .x:hover {
        cursor: pointer;
    }

    .sidebar_box {
        /* margin-left: 600px; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
    }

    .sidebar_box h4 {
        color: #8e1300;
        font-weight: 300;
        font-size: medium;
    }

    .boton {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        height: 50px;
        width: 90%;
        border-radius: 15px;
        border: none;
        font-size: 13px;
        font-weight: 600;
        background-color: #ff847153;
        color: #bc1900;
        border: solid 2px #bc1900;
    }
}
