.loading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
    background-color: #dfdfdfb4;
    height: 350px;
    width: 270px;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.543);
    transition: all 100ms;
}

.container {
	width: 80vw;
    display: flex;
    /* flex-direction: row; */
    border-radius: 15px;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.image {
    width: 270px;
    height: 270px;
    aspect-ratio: 2/3;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #78787836;
}

.title {
    width: 250px;
    height: 20px;
    border-radius: 10px;
    /* border: solid 2px #787878b9; */
    background-color: #78787836;
}
.diets {
    width: 230px;
    height: 20px;
    border-radius: 10px;
    /* border: solid 2px #787878b9; */
    background-color: #78787836;
}

.info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
}
